import avatarManager from './avatar-manager';
import fileManager from './file-manager';
import flashPlugin from "@plugins/flash";
import holidays from "@plugins/holidays";
import vueDatePickerPlugin from "@plugins/vue2-datepicker";

export default {
  avatarManager,
  fileManager,
  flashPlugin,
  holidays,
  vueDatePickerPlugin
}
