import Vue from "vue";
import merge from 'lodash/merge';
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
import fr from 'vue2-datepicker/locale/fr';

const vueDatePickerPlugin = {
  install(app, options) {
    options = merge({
      components: {
        'date-picker': 'vue2-datepicker',
      }
    }, options);

    DatePicker.locale = fr;

    Vue.component('date-picker', DatePicker);
  }
}

Vue.use(vueDatePickerPlugin);

export default vueDatePickerPlugin;
